import { useEffect, useState, useRef, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import Select from "react-select";
import {
  createSearchParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { searchDrugsByName, setDrugsList } from "../../reducers/drugSearch";
import PricesDetails from "./PricesDetails";
import RightReviewCoupon from "./rightReviewCoupon";
import Header from "../partials/Header";
import { useNavigate } from "react-router-dom";
import avatar from "../../assets/images/RxLink assets/avatar icon.svg";
import trash from "../../assets/images/RxLink assets/new assets/Trash_light.svg";
import search from "../../assets/images/RxLink assets/new assets/Search_light.svg";
import { MotionIcon } from "../motion/MotionButton";
import { MotionTextH2 } from "../motion/MotionText";
import { toast } from "react-toastify";
import SearchPharmacy from "../searchPharmacy";
import { getUserPharmacyList, addOrUpdatePharmacy, removePharmacy } from "../searchPharmacy/api";
import { fetchMedication, setFromSearchMap, setPharmacyList, setShowPahrmacyMap, setFromDrawer, setPencilClick, setFromSavings, fetchMedicationDetail, setPharmaInfo } from "../../reducers/medication";
import { setRxSenseToken, setInsuranceInfo, setLoadingPharmacy, setProgress, setProgressLock, setResponseState, resetProgressOperation, setPatient } from "../../reducers/global";
import { filterPharmacyNames } from "../../utilities/FilterPahrmacy";
import { fetchInsuranceInfo } from "../../reducers/getInsuranceInfo";
import { setFromCoupon, setShowCoupon, setTriggerModelFromCoupon } from "../../reducers/medication";
import { getNPIByName, filterNPIResults } from "../../utilities/NPIStore";
import { useStyleMediaQuery } from "../coupan/hooks";
import { store } from "../../store";
import { httpClientRxSense, formData, httpClientJava } from "../../environment";
import { fetchMedicationList } from "../../reducers/javaMedications";
import { fetchCostPlusDrugPrice } from "../../reducers/costPlusDrugPrice";
import { fetchDrugsPrices, fetchRxSenseDrugPrices, setDrugDetails, setRxSenseDrugDetails } from "../../reducers/drugPrices";
import { fetchCopayPrices, fetchPrismaRules, setDisclaimer } from "../../reducers/copayPrices";
import { addOrUpdateMedi, addOrUpdateMedication } from "../../reducers/saveOrUpdateMedication";
import { deleteMedication } from "../../reducers/removeMedication";
import { mapUniqueRxToHippo, mapUniqueHippoToHippo, mapDuplicatePharmacies, mapUniqueCareCardToHippo, mapUniqueRxLessToHippo } from "./mapper";
import { successToast, errorToast, successToastWithId } from "../toast/toast";
import { useWindowWidth } from "../searchPharmacy/useWindowWidth";
import EditPatient from "../patient/editPatient";
import CouponCard from "../coupan/CoupanCard";
import editPencil from "../../assets/images/RxLink assets/new assets/edit pen.svg";
import { fetchEnrollmentList, setViewPatient } from "../../reducers/enrollmentList";
import { getDomainName } from "../../utilities/useGetDomain";
import patient from "../../reducers/patient";
import { useGetUser } from "../../utilities/useGetUser";
import Spinner from 'react-bootstrap/Spinner';
import { mapData } from "./mapData";
import { fetchCareCardInfo } from "../../reducers/careCardInfo";
import { fetchRxLessDrugPrices } from "../../reducers/rxLessDetails";
import { normalizeCompanyName } from "../../utilities/getNormalizedName";
import { customMedications } from "./CustomMedList";

import arrowMobile from "../../assets/images/arrow-forMobile.png";
import mobileImage from "../../assets/images/Mobile-image.png";
import arrowDesktop from "../../assets/images/arrow-forDesktopo.png";
import desktopImage from "../../assets/images/Desktop-Image.png";

const MedicationSearch = ({ ownPosition, setOwnPosition, setIp }) => {
  //state.drugsList.drugs
  const [selectMapValues, setSelectMapValues] = useState([]);
  const [originalFilter, setOriginalFilter] = useState(null);
  const [insuranceDetail, setInsuranceDetail] = useState([]);
  const [hasInsurance, setHasInsurance] = useState(null);
  const [showEditPatient, setShowEditPatient] = useState(false);
  const [addingMedicine, setAddingMedicine] = useState(false);

  const [updatePharmacyToast, setUpdatePharmacyToast] = useState(null);


  const rightReviewRef = useRef(null)
  const { matches: isTab } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 991 });

  const { domainName } = getDomainName();

  const [pharmacyFlag, setPharmacyFlag] = useState(false);

  const [pricesList, setPricesList] = useState([]);
  const [postal, setPostal] = useState("");
  const reduxState = store.getState();
  const fromCoupon = reduxState.medication.fromCoupon;
  const triggerModelFromCoupon = reduxState.medication.triggerModelFromCoupon;
  const savedNpi = reduxState.medication.savedNpi;

  let options = [];
  const drugPrices = useSelector((state) => state.drugdetails.drugPrices);
  const rxSenseDrugPrices = useSelector((state) => state.drugdetails.rxSenseDrugPrices);


  const showPharmacyMap = useSelector((state) => state.medication.showPharmacyMap);
  const fromSearchMap = useSelector((state) => state.medication.fromSearchMap);

  const showCoupon = useSelector((state) => state.medication.showCoupon);
  const pencilClick = useSelector((state) => state.medication.pencilClick);

  const pharmaInfo = useSelector((state) => state.medication.pharmaInfo);

  const progress = useSelector((state) => state.global.progress);
  const progressLock = useSelector((state) => state.global.progressLock);
  const responseState = useSelector((state) => state.global.responseState);

  const pharmacyList = useSelector((state) => state.medication.pharmacyList);
  const fromDrawer = useSelector((state) => state.medication.fromDrawer);
  const fromSavings = useSelector((state) => state.medication.fromSavings);

  const patientInsuranceInfo = useSelector((state) => state.global.insuranceInfo);
  const loadingPharmacy = useSelector((state) => state.global.loadingPharmacy);
  const patient = useSelector((state) => state.global.patient);

  const disclaimer = useSelector((state) => state.copayPrices.disclaimer);

  const hasDefaultPharmacy = pharmacyList?.length > 0 && pharmacyList.filter(i => i.isDefault)?.length > 0 ? true : false;
  // const hasDefaultPharmacy = false;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);


  const [medication, setMedication] = useState({
    drugId: "",
  });
  const { windowWidth } = useWindowWidth();

  const [searchDrug, setSearchDrug] = useState("");

  const [optionss, setOptionss] = useState([]);
  const [medDrugData, setMedDrugData] = useState([]);
  const [medicationsLength, setMedicationsLength] = useState(0);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mapFilterDrugList = (arr) => {
    options = arr.map((element) => {
      return {
        value: element.id,
        label: element.name,
      };
    });
    // console.log(options);
    setOptionss(options);
  };
  const [medicationCoupon, setMedicationCoupon] = useState([]);
  const [SavingOptions, setSavingOptions] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [npi, setNpi] = useState("");
  const [pharmacyInfo, setPharmacyInfo] = useState([]);
  const [medicationList, setMedicationList] = useState([]);
  const [medicationLength, setMedicationLength] = useState(0);
  const [hippoDrugsPrices, setHippoDrugsPrices] = useState([]);
  const [costPlusDrugsPrices, setCostPlusDrugsPrices] = useState([]);
  const [rxSenceDrugsPrices, setRxSenceDrugsPrices] = useState([]);
  const [careCardDrugsPrices, setCareCardDrugsPrices] = useState([]);
  const [rxLessDrugsPrices, setRxLessDrugsPrices] = useState([]);
  const [copayPrices, setCopayPrices] = useState([]);
  const [prismaPrices, setPrismaPrices] = useState([]);
  const [medicationInfo, setMedicationInfo] = useState([]);
  const [sumOfBestPrciesAtPharmacies, setSumOfBestPrciesAtPharmacies] = useState([]);
  const [bestPackage, setBestPackage] = useState([]);
  const [addOrUpdateMed, setAddOrUpdateMed] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [label, setLabel] = useState("");
  const [form, setForm] = useState("");
  const [dosage, setDosage] = useState("");
  const [removeDrug, setRemoveDrug] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [cheaperOptionAvailable, setCheaperOptionAvailable] = useState(false);
  const [savingPercentage, setSavingPercentage] = useState(0);
  // const [patient, setPatient] = useState(0);
  const [enrollmentList, setEnrollmentList] = useState([]);
  const [disabledIcons, setDisabledIcons] = useState({});

  const [checkList, setCheckList] = useState(false);
  var hippoApiErr = 0;

  const ref = useRef(null);
  const regex = new RegExp('pharmacy', 'gi');

  const setPatientInfo = (data) => {
    dispatch(setPatient(data));
  }

  // const userHasPharmacy = JSON.parse(localStorage.getItem("user"))?.pharmacy?.userPharmacySeqNo;

  //const location = useLocation();

  const patientHasInsurance = patient.dob && patient.bin && patient.groupNumber && patient.memberId;


  useEffect(() => {
    if (isTab && triggerModelFromCoupon && rightReviewRef.current && pharmaInfo?.bestPrice) {
      setTimeout(() => { rightReviewRef.current.couponScreenToCouponModel() }, 1000)
    }
    else if (isTab && showCoupon && pharmaInfo?.bestPrice) {
      dispatch(setFromCoupon(true));
      dispatch(setShowCoupon(false))
      dispatch(setTriggerModelFromCoupon(true))
    } else if (!isTab && rightReviewRef.current) {
      rightReviewRef.current.couponModelToCouponScreen();
    }
  }, [isTab, showCoupon, pharmaInfo?.bestPrice])

  useEffect(() => {
    if (!fromCoupon) {
      if (hasDefaultPharmacy && !fromDrawer) {
        dispatch(setShowPahrmacyMap(false))
        dispatch(setFromDrawer(false));
        dispatch(setFromSavings(false));
      }
      else {
        if (!fromSavings) {
          dispatch(setShowPahrmacyMap(true));
        }
        else {
          dispatch(setShowPahrmacyMap(false));
          dispatch(setFromSavings(false));
        }
      }
    }
  }, [hasDefaultPharmacy])

  const user = useGetUser();
  const userPharmacy = pharmacyList?.filter(i => i.isDefault)?.[0];
  const zip = userPharmacy?.userPharmacySeqNo ? userPharmacy.zipcode : ownPosition?.postalCode ? ownPosition?.postalCode : null;
  const userHasPharmacy = hasDefaultPharmacy;

  useEffect(() => {
    if (pharmacyList.length == 0 && userPharmacy?.userPharmacySeqNo) {
      dispatch(setPharmacyList([userPharmacy]))
    }
  }, [userPharmacy])

  useEffect(() => {
    setCheaperOptionAvailable(bestPackage.some(
      (option) => option.bestPrice < pharmaInfo?.bestPrice
    ));
  }, [pharmaInfo])

  useEffect(() => {
    if (bestPackage?.length > 0) {
      let selectedPharmacy = bestPackage?.filter((x, i) => normalizeCompanyName(x?.chain) == normalizeCompanyName(originalFilter?.[0]?.chain) || normalizeCompanyName(x?.chain) == normalizeCompanyName(originalFilter?.[0]?.pharmacyName)?.toLowerCase());
      setPharmacyInfo(selectedPharmacy[0]);
      dispatch(setPharmaInfo(selectedPharmacy[0]))
    }
    else if (bestPackage?.length === 0) {
      setPharmacyInfo({});
      dispatch(setPharmaInfo({}))
    }
  }, [bestPackage])

  useEffect(() => {
    // console.log({valParam});
    let costPercentage = 0;
    costPercentage = (pharmacyInfo?.bestPrice / pharmacyInfo?.estRetailPrice) * 100;
    let percentage = 100 - costPercentage;
    setSavingPercentage(percentage);
  }, [pharmacyInfo]);


  const filteredNpi = useMemo(
    () => {
      let filteredNameItem = filterNPIResults(data, selectMapValues?.pharmacyName);
      // console.log({ filteredNameItem })
      if (filteredNameItem.length > 0) {
        const npi = filteredNameItem?.[0]?.number;
        return npi;
      }
      else {
        let randomNPI = data.length > 0 && data?.[0] != undefined ? data?.[0]?.number : "1801153093";
        return randomNPI;
      }
    }
    ,
    [selectMapValues?.pharmacyName, ownPosition?.postalCode]
  );
  const setter = () => [{ ...selectMapValues, lat: ownPosition?.lat, lng: ownPosition?.lng, state: ownPosition?.responseZIP?.region_code, npi: filteredNpi }];

  useEffect(() => {
    let unmounted = false;
    let allApisCalled = rxLessDrugsPrices?.length === medicationList?.length || careCardDrugsPrices?.length === medicationList?.length || rxSenceDrugsPrices?.length === medicationList?.length || hippoDrugsPrices?.length === medicationList?.length;
    let pricesAvailable = hippoDrugsPrices?.length > 0 || rxSenceDrugsPrices.length > 0 || rxLessDrugsPrices.length > 0 || careCardDrugsPrices.length > 0;
    if (allApisCalled && pricesAvailable && selectMapValues?.length != 0 && hasDefaultPharmacy && !unmounted && !pharmacyFlag) {
      const pharma = pharmacyList.filter(i => i.isDefault)[0];
      if (pharma != undefined && !fromCoupon) {
        let rxSensePharmacies = [];
        rxSenceDrugsPrices?.map(res => {
          res?.rxsenseResponse?.value?.pharmacyPricings?.map((pharmacy) => {
            rxSensePharmacies?.push(pharmacy?.pharmacy?.name?.toLowerCase());
          })
        });

        let hippoPharmacies = [];
        hippoDrugsPrices?.map(res => {
          res?.drugPrices?.map((pharmacy) => {
            hippoPharmacies?.push(pharmacy?.chain?.toLowerCase());
          })
        });
        const filterRes = filterPharmacyNames(normalizeCompanyName(pharma.pharmacyName),
          [
            ...new Set([
              ...(hippoPharmacies?.map(i => i?.toLowerCase()) || []),
              ...(rxSensePharmacies?.map(i => i?.toLowerCase()) || []),
              ...(rxLessDrugsPrices?.flatMap(i => i?.rxLessResponse?.flatMap(j => j?.name?.toLowerCase()) || []) || []),
              ...(careCardDrugsPrices?.flatMap(i => i?.careCardResponse?.flatMap(j => j?.pharmacy?.name.toLowerCase()) || []) || [])
            ])
          ].filter(Boolean));
        let filterOriginalPharmacy = [];
        hippoDrugsPrices?.map(res => {
          res?.drugPrices?.map((pharmacy) => {
            if (pharmacy?.chain?.toLowerCase() == normalizeCompanyName(filterRes?.name?.toLowerCase())) filterOriginalPharmacy.push(pharmacy);
          })
        });

        if (filterOriginalPharmacy?.length > 0) {
          setOriginalFilter([{ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, address: pharma.address, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }]);
          sessionStorage.setItem("selectMapValues", JSON.stringify({ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }));
        }
        if (filterOriginalPharmacy?.length === 0 && rxSenseDrugPrices.length > 0) {
          rxSenceDrugsPrices?.map(res => {
            res?.rxsenseResponse?.value?.pharmacyPricings?.map((pharmacy) => {
              if (pharmacy?.pharmacy?.name?.toLowerCase() == normalizeCompanyName(filterRes?.name?.toLowerCase()))
                filterOriginalPharmacy.push({
                  ...pharmacy.Pharmacy,
                  pharmacyName: pharmacy?.pharmacy?.name?.toLowerCase(),
                  chain: pharmacy?.pharmacy?.name?.toLowerCase()
                });
            })
          });
          if (filterOriginalPharmacy?.length > 0) {
            setOriginalFilter([{ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, address: pharma.address, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }]);
            sessionStorage.setItem("selectMapValues", JSON.stringify({ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }));
          }
        }

        if (filterOriginalPharmacy?.length === 0 && rxLessDrugsPrices?.length > 0) {
          filterOriginalPharmacy = (rxLessDrugsPrices?.flatMap(i => i?.rxLessResponse?.flatMap(j => j) || []) || [])?.filter(i => i?.name?.toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").trim() === filterRes?.name?.toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").trim());
          if (filterOriginalPharmacy?.length > 0) {
            setOriginalFilter([{ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, address: pharma.address, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }]);
            sessionStorage.setItem("selectMapValues", JSON.stringify({ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }));
          }
        }
        if (filterOriginalPharmacy?.length === 0 && careCardDrugsPrices?.length > 0) {
          (careCardDrugsPrices?.flatMap(i => i?.careCardResponse?.flatMap(j => j) || []) || [])?.
            filter(i => {

              if (i?.pharmacy?.name?.toLowerCase() === normalizeCompanyName(filterRes?.name?.toLowerCase())) {
                filterOriginalPharmacy.push({
                  ...i,
                  pharmacyName: i?.pharmacy?.name?.toLowerCase()
                })
              }
              else return false
            });
          if (filterOriginalPharmacy?.length > 0) {
            setOriginalFilter([{ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, address: pharma.address, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }]);
            sessionStorage.setItem("selectMapValues", JSON.stringify({ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }));
          }
        }
        if (filterOriginalPharmacy?.length === 0) {
          setOriginalFilter([pharma]);
        }
      }
      else if (fromCoupon) {
        setOriginalFilter([{ ...selectMapValues, lat: ownPosition?.lat, lng: ownPosition?.lng, state: ownPosition?.responseZIP?.region_code, npi: savedNpi }]);
      }
      else {
        setOriginalFilter(setter());
      }
    }
    else if (allApisCalled && pricesAvailable && selectMapValues?.length != 0 && hasDefaultPharmacy && !unmounted && pharmacyFlag) {
      setOriginalFilter(setter());
    }
    else if (allApisCalled && pricesAvailable && selectMapValues?.length != 0 && !hasDefaultPharmacy && !unmounted && !pharmacyFlag) {
      setOriginalFilter(setter());
    }
    else if (allApisCalled && pricesAvailable && selectMapValues?.length != 0 && !hasDefaultPharmacy && !unmounted && pharmacyFlag) {
      setOriginalFilter(setter());
    }
    return () => {
      unmounted = true
    }
  }, [hippoDrugsPrices, rxSenceDrugsPrices, rxLessDrugsPrices, careCardDrugsPrices, selectMapValues]);



  useEffect(() => {
    if (addOrUpdateMed?.appUserSeqNo) {
      if (!addingMedicine) {
        setAddingMedicine(true);
      }
      let body = {
        ...addOrUpdateMed,
        domainName: domainName,
        patientSeqNo: localStorage.getItem("patientSeqNo")
      }
      addOrUpdateMedic(body);
    }
  }, [addOrUpdateMed])

  const addOrUpdateMedic = async (body) => {
    await addOrUpdateMedi(body, (async (x) => {
      if (x?.data?.status == "OK") {
        let index = medicationList?.findIndex((el, i) => el?.drugSeqNo === x?.data?.data?.drugSeqNo);
        if (index !== -1) {
          const newArr = [
            ...medicationList.slice(0, index),
            Object.assign({}, medicationList[index], x?.data?.data),
            ...medicationList.slice(index + 1),
          ];
          setMedicationList(newArr);
          if (patient?.bin && patient?.groupNumber && patient?.memberId) {
            await fetchCopayInfo(newArr);
          }
        }
        else {
          setMedicationList([...medicationList, x?.data?.data]);
          if (patient?.bin && patient?.groupNumber && patient?.memberId) {
            await fetchCopayInfo([...medicationList, x?.data?.data]);
          }
        }
        let tempSelectedDrug = {
          name: x?.data?.data?.name,
          id: x?.data?.data?.id,
          type: x?.data?.data?.type,
          search_name: x?.data?.data?.search_name,
          dosage: x?.data?.data?.dosage,
          drugSeqNo: x?.data?.data?.drugSeqNo,
          form: x?.data?.data?.form,
          form_plural: x?.data?.data?.form_plural,
          package_size: x?.data?.data?.package_size,
          quantities: x?.data?.data?.quantities,
          default_quantity: x?.data?.data?.default_quantity !== undefined ? x?.data?.data?.default_quantity : JSON.parse(x?.data?.data?.quantities)[0],
          names: x?.data?.data?.names,
          ndc: x?.data?.data?.ndc,
          manufacturerOffer: x?.data?.data?.manufactorInfo,
          connectiveRxOffer: x?.data?.data?.crxNetPriceDTO,
        };
        dispatch(resetProgressOperation("reset"));
        dispatch(setProgress(15));
        await setHippoPrices(tempSelectedDrug, x?.data?.data);
        await setCostPlusDrugPrices(tempSelectedDrug, x?.data?.data);
        await setRxSencePrices(tempSelectedDrug, x?.data?.data);
        await setCareCardInfo(tempSelectedDrug, x?.data?.data);
        setRxLessPrices([tempSelectedDrug])
      }
      else {
        errorToast(x?.data?.message);
        setAddingMedicine(false);
      }
    }))


  }

  useEffect(() => {
    if (removeDrug?.drugSeqNo) {//drugSeqNo
      let body = {
        appUserSeqNo: JSON.parse(localStorage.getItem("user"))?.appUserSeqNo,
        patientSeqNo: +localStorage.getItem("patientSeqNo"),
        drugSeqNo: removeDrug?.drugSeqNo,
        domainName: domainName
      };
      let id = removeDrug?.drugSeqNo;
      if (disabledIcons[id]) return;
      setDisabledIcons(prevState => ({ ...prevState, [id]: true }));
      dispatch(deleteMedication(body))
        .unwrap()
        .then((response) => {
          setDisabledIcons(prevState => ({ ...prevState, [id]: false }));
          if (response?.status === "OK") {
            successToastWithId(response?.message, "remove-medication");
            setMedicationInfo((medicationInfo) => medicationInfo?.filter((x) => x?.selectedDrug?.drugSeqNo !== removeDrug?.drugSeqNo));
            setMedicationList((medicationList) => medicationList?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
            setHippoDrugsPrices((hippoDrugsPrices) => hippoDrugsPrices?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
            setCostPlusDrugsPrices((costPlusDrugsPrices) => costPlusDrugsPrices?.filter((x) => x?.selectedDrug?.drugSeqNo !== removeDrug?.drugSeqNo));
            setRxSenceDrugsPrices((rxSenceDrugsPrices) => rxSenceDrugsPrices?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
            setCareCardDrugsPrices((careCardDrugsPrices) => careCardDrugsPrices?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
            setRxLessDrugsPrices((rxLessDrugPrices) => rxLessDrugPrices?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
            setCopayPrices((copayPrices) => copayPrices?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
            dispatch(setDisclaimer(disclaimer?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo)));
            setPrismaPrices(prismaPrices?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
          }
        })
        .catch((e) => {
          setDisabledIcons(prevState => ({ ...prevState, [id]: false }));
        }).finally((x) => {
        });
    }
  }, [removeDrug])

  useEffect(() => {
    if (showPharmacyMap == true) {
      setMedication({
        drugId: "",
      })
    }
  }, [showPharmacyMap])

  useEffect(() => {
    if (ownPosition) {
      setCheckList(true)
      getUserPharmacyList((data) => {
        if (data.status == 200)
          dispatch(setPharmacyList(data.data.data.sort((a, b) => b.isDefault - a.isDefault)))
        else {
          errorToast("There might be problem fetching your pharmacies please try again!")
        }
      })
    }
    if (user && ownPosition == null) {
      setIp(user?.proxyIpAddress)
    }
  }, [ownPosition])

  useEffect(() => {
    if (checkList) {
      const user = JSON.parse(localStorage.getItem("user"));
      getPatientSavingsHistory(user?.appUserSeqNo);
      // setCheckList(false)
    }
  }, [checkList])

  useEffect(() => {
    setSavingOptions(pencilClick);
    const element = document.getElementById("listingContent");
    if (element) {
      element.scrollIntoView();
    }

    if (state?.ndc || state?.rxNorm) {
      setMedication({
        ...medication,
        ndc: state?.ndc,
        rxNorm: state?.rxNorm,
      });
    }
  }, [state]);

  useEffect(() => {
    if (searchDrug) {
      // setLoading(true);
      if(searchDrug?.toLowerCase() === "vyndaqel"){
        setOptionss([{name:"vyndaqel", id: "vyndaqel", ndc: "0069197512", secondary_name: ""}]);
        dispatch(setDrugsList([{name:"vyndaqel", id: "vyndaqel", ndc: "0069197512", secondary_name: ""}]));
        return;
      }
      dispatch(searchDrugsByName(searchDrug))
        .unwrap()
        .then((response) => {
          // mapFilterDrugList(response?.hits);
          setOptionss(response?.hits);
          // console.log(optionss);
          dispatch(setDrugsList(response?.hits));
          // if drug not found
          if (response.hits == 0) {
            toast.dismiss();
            errorToast("Medication not found");
          }
        })
        .catch((e) => {
          //console.log(e);
        }).finally((x) => {
          // setLoading(false);
        });
    }

  }, [searchDrug]);

  useEffect(() => {
    if (medicationList?.length > 0) {
      checkProgress();
      let prismaCheck = patientHasInsurance && prismaPrices?.length == medicationList?.length ? true : !patientHasInsurance ? true : false;
      if (prismaCheck || (rxLessDrugsPrices?.length === medicationList?.length || careCardDrugsPrices?.length === medicationList?.length || rxSenceDrugsPrices?.length === medicationList?.length || hippoDrugsPrices?.length === medicationList?.length)) {
        if (addingMedicine) {
          setAddingMedicine(false)
        }
        if (copayPrices?.length > 0) {
          calculateBestPrices(hippoDrugsPrices, costPlusDrugsPrices, copayPrices, rxSenceDrugsPrices, careCardDrugsPrices, rxLessDrugsPrices, prismaPrices);
        }
        else {
          calculateBestPrices(hippoDrugsPrices, costPlusDrugsPrices, null, rxSenceDrugsPrices, careCardDrugsPrices, rxLessDrugsPrices, prismaPrices);
        }
      }
    }
    else {
      setSumOfBestPrciesAtPharmacies([]);
      setMedicationInfo([]);
    }

  }, [originalFilter, hippoDrugsPrices, costPlusDrugsPrices, copayPrices, prismaPrices, rxSenceDrugsPrices, careCardDrugsPrices, rxLessDrugsPrices])

  const getBestPrices = (copay, hippo, RxSence, careCard, rxLess, prisma) => {

    // console.log("BestPriceObject", getBestPriceObject(copay, hippo, RxSence, careCard));
    const getRetailPrice = (uc, price, hippoRetail) => {
      if (uc == 0) {
        //if (hippoRetail > price) return hippoRetail
        //  else 
        return price;
      }
      if (uc > 0) {
        if (uc >= price) return uc;
        else if (uc < price) {
          if (hippoRetail > price) return hippoRetail;
          else return price
        }
      }
    }
    let bestPriceObj = getBestPriceObject(copay, hippo, RxSence, careCard, rxLess, prisma);

    if (copay && bestPriceObj?.isCopay) {
      return {
        //copay;
        estRetailPrice: hippo?.estRetailPrice > 0 ? hippo?.estRetailPrice : copay?.prices?.billing_response?.pricing?.patient_pay / 100,
        bestPrice: copay?.prices?.billing_response?.pricing?.patient_pay / 100,
        description: "with insurance",
        hasInsurance: true,
        rxSense: false,
        isCareCard: false,
        isHippo: false,
        isRxless: false,
        InsuranceInfo: {
          bin: copay?.bin,
          pcn: copay?.pcn,
          ID: copay?.memberId,
          Group: copay?.groupNumber
        },
        careCardInfo: {}
      }
    }
    else if (prisma && bestPriceObj?.isPrisma) {
      return {
        // prisma;
        estRetailPrice: hippo?.estRetailPrice > 0 ? hippo?.estRetailPrice : copay?.prices?.billing_response?.pricing?.patient_pay / 100,
        bestPrice: parseFloat(prisma?.price),
        description: "with insurance",
        hasInsurance: true,
        rxSense: false,
        isCareCard: false,
        isHippo: false,
        isRxless: false,
        isPrisma: true,
        InsuranceInfo: {
          bin: copay?.bin,
          pcn: copay?.pcn,
          ID: copay?.memberId,
          Group: copay?.groupNumber
        },
        careCardInfo: {}
      }
    }
    else if (hippo && bestPriceObj?.isHippo) {
      return {
        // hippo;
        estRetailPrice: hippo?.estRetailPrice > 0 ? hippo?.estRetailPrice : copay?.prices?.billing_response?.pricing?.patient_pay / 100,
        bestPrice: hippo?.cost,
        description: "with discount card",
        hasInsurance: false,
        rxSense: false,
        isCareCard: false,
        isHippo: true,
        isRxless: false,
        InsuranceInfo: {

        },
        careCardInfo: {}
      }
    }
    else if (RxSence && bestPriceObj?.isRxSence) {
      return {
        //RxSence;
        estRetailPrice: getRetailPrice(RxSence?.prices[0]?.usualAndCustomary, RxSence?.prices[0]?.price, hippo?.estRetailPrice),
        //RxSence?.Prices[0]?.UsualAndCustomary > 0 ? RxSence?.Prices[0]?.UsualAndCustomary : RxSence?.Prices[0]?.Price,
        bestPrice: parseFloat(RxSence?.prices[0]?.price),
        description: "with Single Care",
        hasInsurance: false,
        rxSense: true,
        isCareCard: false,
        isHippo: false,
        isRxless: false,
        InsuranceInfo: {

        },
        careCardInfo: {}
      }
    }
    else if (careCard && bestPriceObj?.isCareCard) {
      return {
        //CareCard;
        estRetailPrice: careCard?.priceBeforeDiscount ? careCard?.priceBeforeDiscount : 0,
        bestPrice: careCard?.price,
        description: "with Care Card",
        hasInsurance: false,
        rxSense: false,
        isCareCard: true,
        isHippo: false,
        isRxless: false,
        InsuranceInfo: {

        },
        careCardInfo: {
          bin: careCard?.bin,
          groupId: careCard?.groupId,
          memberId: careCard?.memberId,
          pcn: careCard?.pcn,
          ndc: careCard?.ndc
        }
      }
    }

    else if (rxLess && bestPriceObj?.isRxLess) {
      return {
        //CareCard;
        estRetailPrice: getRetailPrice(0, rxLess?.price, hippo?.estRetailPrice),
        //RxSence?.Prices[0]?.UsualAndCustomary > 0 ? RxSence?.Prices[0]?.UsualAndCustomary : RxSence?.Prices[0]?.Price,
        bestPrice: rxLess?.price,
        description: "with RxLess",
        hasInsurance: false,
        rxSense: false,
        isCareCard: false,
        isHippo: false,
        isRxless: true,
        InsuranceInfo: {

        },
        careCardInfo: {
          bin: rxLess?.bin,
          groupId: rxLess?.groupId,
          memberId: rxLess?.memberId,
          pcn: rxLess?.pcn,
          ndc: rxLess?.ndc
        }
      }
    }
  }

  const getNormalizedPrice = (object) => {
    if (object?.prices?.billing_response?.pricing?.patient_pay / 100 >= 0) return object?.prices?.billing_response?.pricing?.patient_pay / 100;
    if (object?.cost > 0) return object.cost;
    if (parseFloat(object?.prices?.[0]?.price || "0") > 0) return parseFloat(object?.prices[0].price);
    if (object?.price >= 0) return object.price;
    return Infinity; // If no price property is found, return a high value to exclude it
  };

  const getBestPriceObject = (copay, hippo, rxSence, careCard, rxLess, prisma) => {

    const normalizedPrice1 = getNormalizedPrice(copay);
    const normalizedPrice2 = getNormalizedPrice(hippo);
    const normalizedPrice3 = getNormalizedPrice(rxSence);
    const normalizedPrice4 = getNormalizedPrice(careCard);
    const normalizedPrice5 = getNormalizedPrice(rxLess);

    let bestPriceObject = {};
    let minPrice = Infinity;

    // Check Copay first
    if (normalizedPrice1 && normalizedPrice1 < minPrice) {
      bestPriceObject = { ...copay, isCopay: true };
      minPrice = normalizedPrice1;
    }

    // Create an array of offers and sort according to price and priority
    const offers = [
      { price: normalizedPrice3, object: { ...rxSence, isRxSence: true }, provider: 'RxSense' },
      { price: normalizedPrice2, object: { ...hippo, isHippo: true }, provider: 'Hippo' },
      { price: normalizedPrice5, object: { ...rxLess, isRxLess: true }, provider: 'RxLess' },
      { price: normalizedPrice4, object: { ...careCard, isCareCard: true }, provider: 'CareCard' }
    ];

    // Filter out invalid offers (price is undefined or null)
    const validOffers = offers.filter(offer => offer?.price != null);

    // Sort offers by price and then by priority based on your priority order
    validOffers.sort((a, b) => {
      if (a.price !== b.price) {
        return a.price - b.price; // Sort by price first
      }
      // Sort by priority if prices are equal
      const priorityOrder = ['RxSense', 'Hippo', 'RxLess', 'CareCard'];
      return priorityOrder.indexOf(a.provider) - priorityOrder.indexOf(b.provider);
    });

    // Check the first valid offer with the lowest price
    if (validOffers.length > 0 && validOffers[0].price < minPrice) {
      bestPriceObject = validOffers[0].object;
      minPrice = validOffers[0].price;
    }

    if (prisma?.price != null && parseFloat(prisma?.price) < minPrice && bestPriceObject?.isCopay !== true) {
      bestPriceObject = { ...prisma, prismaPrice: parseFloat(prisma?.price), isPrisma: true };
      minPrice = parseFloat(prisma?.price);
    }

    return bestPriceObject;
  };


  // getUniquePharmacies will recieve pharmacies from hippo and rxSense as parameters
  // and return a unique list of pharmacies
  const getUniquePharmacies = (hippo, rxSense, careCard, rxLess) => {
    if (!hippo && !rxSense && !careCard && !rxLess) return [];
    let mappedHippo, mappedRx, mappedCareCard, mappedRxLess;
    if (hippo) {
      mappedHippo = hippo?.map((pharmacy) => mapUniqueHippoToHippo(pharmacy))
      //return [...mappedHippo];
    }
    if (rxSense) {
      mappedRx = rxSense?.map((pharmacy) => mapUniqueRxToHippo(pharmacy))
      //return [...mappedRx];
    }

    if (careCard) {
      mappedCareCard = careCard?.map((pharmacy) => mapUniqueCareCardToHippo(pharmacy))
    }

    if (rxLess) {
      mappedRxLess = rxLess?.map((pharmacy) => mapUniqueRxLessToHippo(pharmacy))
    }


    const getUniqueObjects = (array, propertyName) => {
      return array.filter((item, index, self) =>
        index === self.findIndex((t) => t[propertyName] === item[propertyName])
      );
    }
    const pharmacies = [mappedHippo, mappedRx, mappedCareCard, mappedRxLess];
    // Filter out null or undefined pharmacies
    const validPharmacies = pharmacies.filter(arr => arr != null || arr != undefined);

    // Combine all valid pharmacies into one
    const combinedValidPharmacies = [].concat(...validPharmacies);
    const uniquePharmacies = getUniqueObjects(combinedValidPharmacies, "chain");
    //debugger
    return [...uniquePharmacies];
  }
  const getDrugsArray = (array, index, drug, pharmacyInfo) => {


    console.log(array[index]?.drugs?.map(item => item?.selectedDrug?.drugSeqNo));
    let ind = array[index]?.drugs?.map(item => item?.selectedDrug?.drugSeqNo)?.indexOf(drug?.selectedDrug?.drugSeqNo);
    console.log(ind === -1 ? 0 : ind);

    console.log(array[index]?.drugs.splice(array[index]?.drugs?.map(item => item?.selectedDrug?.drugSeqNo)?.indexOf(drug?.selectedDrug?.drugSeqNo) === -1 ? 0 : array[index]?.drugs?.map(item => item?.selectedDrug?.drugSeqNo)?.indexOf(drug?.selectedDrug?.drugSeqNo), array[index]?.drugs?.map(item => item?.selectedDrug?.drugSeqNo)?.indexOf(drug?.selectedDrug?.drugSeqNo) === -1 ? 0 : 1, drug));
    // debugger;


    let response = array[index]?.drugs.map((pharma, index) => {
      return {
        ...pharma,
        chain: pharmacyInfo?.chain,
        pharmacyName: pharmacyInfo?.pharmacyName,
        logo: pharmacyInfo?.logo
      }
    })
    return response;
  }

  function calculateBestPrices(hippoDrugsPrices, costPlusDrugsPrices, copayPrices, rxSenceDrugsPrices, careCardDrugsPrices, rxLessDrugPrices, prismaPrices) {
    let array = [];

    // Function to find an element by drugSeqNo in an array
    function findByDrugSeqNo(arr, drugSeqNo) {
      return arr?.find(item => item?.selectedDrug?.drugSeqNo === drugSeqNo);
    }

    // Iterate through hippoDrugsPrices
    hippoDrugsPrices?.forEach((element) => {
      // Find corresponding item in costPlusDrugsPrices
      let sub_element = findByDrugSeqNo(costPlusDrugsPrices, element?.drugSeqNo);
      // if (!sub_element) return;

      // Find corresponding item in rxSenceDrugsPrices
      let ele = findByDrugSeqNo(rxSenceDrugsPrices, element?.drugSeqNo);
      // if (!ele) return;

      // Find corresponding item in careCardDrugsPrices
      let careCardEle = findByDrugSeqNo(careCardDrugsPrices, element?.drugSeqNo);
      // if (!careCardEle) return;

      // Find corresponding item in rxLessDrugPrices
      let rxLess = findByDrugSeqNo(rxLessDrugPrices, element?.drugSeqNo);
      // if (!rxLess) return;

      // Process copayPrices
      let tempCopayArr = copayPrices?.filter(x => x?.drugSeqNo === element?.drugSeqNo);
      // if (tempCopayArr?.length === 0) return;

      let tempPrismaArr = prismaPrices?.filter(x => x?.drugSeqNo === element?.drugSeqNo);

      // Process other data
      let selectedPharmacy = normalizeCompanyName(originalFilter?.[0]?.chain) ? normalizeCompanyName(originalFilter?.[0]?.chain) : normalizeCompanyName(user?.pharmacy?.pharmacyName?.toLowerCase());
      let temphippoPrice1 = element?.drugPrices?.filter(x => normalizeCompanyName(x?.chain) === selectedPharmacy);
      let tempRxSencePrice1 = ele?.rxsenseResponse?.value?.pharmacyPricings?.filter(x => normalizeCompanyName(x?.pharmacy.name) == selectedPharmacy);
      let tempCareCardPrice1 = careCardEle?.careCardResponse?.filter(x => normalizeCompanyName(x?.chain) == selectedPharmacy);
      let tempRxLessPrice = rxLess?.rxLessResponse?.filter(x => normalizeCompanyName(x?.chain) == selectedPharmacy);
      let res = getUniquePharmacies(element?.drugPrices, ele?.rxsenseResponse?.value?.pharmacyPricings, careCardEle?.careCardResponse, rxLess?.rxLessResponse);

      let bestCostObj1 = getBestPrices(
        tempCopayArr?.[0],
        temphippoPrice1?.[0],
        tempRxSencePrice1?.[0],
        tempCareCardPrice1?.[0],
        tempRxLessPrice?.[0],
        tempPrismaArr?.[0]
      );

      // Construct tempMedArr1
      let tempMedArr1 = [{
        drugSeqNo: element?.drugSeqNo,
        selectedDrug: element?.selectedDrug,
        selectedQty: element?.selectedDrug?.default_quantity,
        selectedLabel: element?.selectedDrug?.name,
        selectedForm: element?.selectedDrug?.form,
        selectedDosage: element?.selectedDrug?.dosage,
        isExpandable: false,
        costPlusDrugPrice: sub_element?.costPlusDrugPrice,
        estRetailPrice: bestCostObj1?.estRetailPrice || 0,
        bestPrice: bestCostObj1?.bestPrice || 0,
        patient_pay: tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay >= 0 ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay : 0,
        amount_of_copay: tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay >= 0 ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay : 0,
        hippoPrice: temphippoPrice1?.[0]?.cost,
        rxSensePrice: tempRxSencePrice1?.[0]?.prices?.[0]?.price ? parseFloat(tempRxSencePrice1?.[0]?.prices?.[0]?.price) : undefined,
        careCardPrice: tempCareCardPrice1?.[0]?.price,
        rxLessPrice: tempRxLessPrice?.[0]?.price,
        chain: temphippoPrice1?.[0]?.chain ? temphippoPrice1?.[0]?.chain.toLowerCase() : tempRxSencePrice1?.[0]?.pharmacy?.name ? tempRxSencePrice1?.[0]?.pharmacy?.name.toLowerCase() : tempCareCardPrice1?.[0]?.chain ? tempCareCardPrice1?.[0]?.chain.toLowerCase() : tempRxLessPrice?.[0]?.chain.toLowerCase(),
        logo: temphippoPrice1?.[0]?.logo,
        pharmacyName: temphippoPrice1?.[0]?.pharmacyName,
        description: bestCostObj1?.description,
        hasInsurance: bestCostObj1?.hasInsurance,
        rxSense: bestCostObj1?.rxSense,
        InsuranceInfo: bestCostObj1?.InsuranceInfo,
        careCardInfo: bestCostObj1?.careCardInfo,
        isHippo: bestCostObj1?.isHippo,
        isCareCard: bestCostObj1?.isCareCard,
        isRxLess: bestCostObj1?.isRxless,
        prismaDrugPrice: tempPrismaArr?.[0]?.price != null ? tempPrismaArr?.[0]?.price : null
      }];

      // Process res (assuming it comes from getUniquePharmacies)
      res?.forEach((drugPr) => {
        let temphippoPrice = element?.drugPrices?.filter(x => normalizeCompanyName(x?.chain) === drugPr?.chain);
        let tempRxSencePrice = ele?.rxsenseResponse?.value?.pharmacyPricings?.filter(x => normalizeCompanyName(x?.pharmacy.name) == drugPr?.pharmacyName);
        let tempCareCardPrice = careCardEle?.careCardResponse?.filter(x => normalizeCompanyName(x?.chain) == drugPr?.chain);
        let tempRxLessPrice = rxLess?.rxLessResponse?.filter(x => normalizeCompanyName(x?.chain) == drugPr?.chain);

        let bestCostObj = getBestPrices(
          tempCopayArr?.[0],
          temphippoPrice?.[0],
          tempRxSencePrice?.[0],
          tempCareCardPrice?.[0],
          tempRxLessPrice?.[0],
          tempPrismaArr?.[0]
        );

        let tempMedArr = [{
          selectedDrug: element?.selectedDrug,
          selectedQty: element?.selectedDrug?.default_quantity,
          selectedLabel: element?.selectedDrug?.name,
          selectedForm: element?.selectedDrug?.form,
          selectedDosage: element?.selectedDrug?.dosage,
          drugSeqNo: element?.drugSeqNo,
          isExpandable: false,
          costPlusDrugPrice: sub_element?.costPlusDrugPrice,
          estRetailPrice: bestCostObj?.estRetailPrice || 0,
          bestPrice: bestCostObj?.bestPrice || 0,
          patient_pay: tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay >= 0 ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay : 0,
          amount_of_copay: tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay >= 0 ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay : 0,
          hippoPrice: temphippoPrice?.[0]?.cost,
          rxSensePrice: tempRxSencePrice?.[0]?.prices?.[0]?.price ? parseFloat(tempRxSencePrice?.[0]?.prices?.[0]?.price) : undefined,
          careCardPrice: tempCareCardPrice?.[0]?.price,
          rxLessPrice: tempRxLessPrice?.[0]?.price,
          chain: drugPr?.chain,
          logo: drugPr?.logo,
          pharmacyName: drugPr?.pharmacyName,
          description: bestCostObj?.description,
          hasInsurance: bestCostObj?.hasInsurance,
          rxSense: bestCostObj?.rxSense,
          isCareCard: bestCostObj?.isCareCard,
          hasDiscount: bestCostObj?.isHippo ? true : false,
          InsuranceInfo: {
            bin: tempCopayArr?.[0]?.bin,
            pcn: tempCopayArr?.[0]?.pcn,
            ID: tempCopayArr?.[0]?.memberId,
            Group: tempCopayArr?.[0]?.groupNumber
          },
          prismaDrugPrice: tempPrismaArr?.[0]?.price != null ? tempPrismaArr?.[0]?.price : null
        }];

        // Update array with new or merged data
        if (array.length > 0) {
          let i = array.findIndex(x => x?.chain === drugPr?.chain);
          if (i !== -1) {
            let updatedObj = {
              drugSeqNo: tempMedArr[0]?.drugSeqNo,
              drugs: getDrugsArray(array, i, tempMedArr[0], drugPr),
              costPlusDrugPrice: sub_element?.costPlusDrugPrice,
              estRetailPrice: array[i]?.estRetailPrice + tempMedArr[0]?.estRetailPrice,
              bestPrice: array[i]?.bestPrice + tempMedArr[0]?.bestPrice,
              patient_pay: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay || 0 : 0,
              amount_of_copay: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay || 0 : 0,
              hippoPrice: drugPr?.cost,
              rxSensePrice: drugPr?.rx?.prices?.[0]?.price ? parseFloat(drugPr?.rx?.prices[0].price) : undefined,
              careCardPrice: tempMedArr[0]?.careCardPrice,
              rxLessPrice: tempMedArr[0]?.rxLessPrice,
              chain: drugPr?.chain,
              logo: drugPr?.logo,
              pharmacyName: drugPr?.pharmacyName,
              description: tempMedArr[0]?.description,
              hasInsurance: tempMedArr[0]?.hasInsurance,
              rxSense: tempMedArr[0]?.rxSense,
              isCareCard: tempMedArr[0]?.isCareCard,
              hasDiscount: tempMedArr[0]?.isHippo ? true : false,
              InsuranceInfo: {
                bin: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.bin : "",
                pcn: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.pcn : "",
                ID: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.memberId : "",
                Group: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.groupNumber : ""
              },
              prismaDrugPrice: tempPrismaArr?.[0]?.price != null ? tempPrismaArr?.[0]?.price : null
            };
            array = [
              ...array.slice(0, i),
              Object.assign({}, array[i], updatedObj),
              ...array.slice(i + 1)
            ];
          } else {
            array.push({
              drugSeqNo: tempMedArr[0]?.drugSeqNo,
              drugs: tempMedArr,
              costPlusDrugPrice: sub_element?.costPlusDrugPrice,
              estRetailPrice: tempMedArr[0]?.estRetailPrice,
              bestPrice: tempMedArr[0]?.bestPrice,
              patient_pay: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay || 0 : 0,
              amount_of_copay: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay || 0 : 0,
              hippoPrice: drugPr?.cost,
              rxSensePrice: drugPr?.rx?.prices?.[0]?.price ? parseFloat(drugPr?.rx?.prices[0].price) : undefined,
              careCardPrice: tempMedArr[0]?.careCardPrice,
              rxLessPrice: tempMedArr[0]?.rxLessPrice,
              chain: drugPr?.chain,
              logo: drugPr?.logo,
              pharmacyName: drugPr?.pharmacyName,
              description: tempMedArr[0]?.description,
              hasInsurance: tempMedArr[0]?.hasInsurance,
              rxSense: tempMedArr[0]?.rxSense,
              isCareCard: tempMedArr[0]?.isCareCard,
              hasDiscount: tempMedArr[0]?.isHippo ? true : false,
              InsuranceInfo: {
                bin: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.bin : "",
                pcn: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.pcn : "",
                ID: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.memberId : "",
                Group: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.groupNumber : ""
              },
              prismaDrugPrice: tempPrismaArr?.[0]?.price != null ? tempPrismaArr?.[0]?.price : null
            });
          }
        } else {
          array.push({
            drugSeqNo: tempMedArr[0]?.drugSeqNo,
            drugs: tempMedArr,
            costPlusDrugPrice: sub_element?.costPlusDrugPrice,
            estRetailPrice: tempMedArr[0]?.estRetailPrice,
            bestPrice: tempMedArr[0]?.bestPrice,
            patient_pay: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay || 0 : 0,
            amount_of_copay: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay || 0 : 0,
            hippoPrice: drugPr?.cost,
            rxSensePrice: drugPr?.rx?.prices?.[0]?.price ? parseFloat(drugPr?.rx?.prices[0].price) : undefined,
            careCardPrice: tempMedArr[0]?.careCardPrice,
            rxLessPrice: tempMedArr[0]?.rxLessPrice,
            chain: drugPr?.chain,
            logo: drugPr?.logo,
            pharmacyName: drugPr?.pharmacyName,
            description: tempMedArr[0]?.description,
            hasInsurance: tempMedArr[0]?.hasInsurance,
            rxSense: tempMedArr[0]?.rxSense,
            isCareCard: tempMedArr[0]?.isCareCard,
            hasDiscount: tempMedArr[0]?.isHippo ? true : false,
            InsuranceInfo: {
              bin: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.bin : "",
              pcn: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.pcn : "",
              ID: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.memberId : "",
              Group: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.groupNumber : ""
            },
            prismaDrugPrice: tempPrismaArr?.[0]?.price != null ? tempPrismaArr?.[0]?.price : null
          });
        }
      });

      // Set state or update values as needed
      setSumOfBestPrciesAtPharmacies(array.sort((a, b) => a?.bestPrice - b?.bestPrice));

      setMedicationInfo(medicationInfo => medicationInfo?.filter((x, i) => x?.selectedDrug?.drugSeqNo !== element?.selectedDrug?.drugSeqNo).concat(tempMedArr1));
    });

    // Further logic after processing array
    // Further logic after processing array
    let userLoginResPharmacy = user?.pharmacy;
    let allApisCalled = rxLessDrugsPrices?.length === medicationList?.length && careCardDrugsPrices?.length === medicationList?.length && rxSenceDrugsPrices?.length === medicationList?.length && hippoDrugsPrices?.length === medicationList?.length;
    if (array?.length > 0) {
      if (selectMapValues?.length === 0 || !allApisCalled) {
        if (userLoginResPharmacy?.pharmacyName) {
          setSelectMapValues(userLoginResPharmacy)
        }
        else
          setSelectMapValues(array
            .filter(item => item.bestPrice > 0 || item.hasInsurance)
            .sort((a, b) => a.bestPrice - b.bestPrice)[0]);
      } else {
        let checkArray = array.filter(item => item?.pharmacyName?.toLowerCase() == selectMapValues?.pharmacyName?.toLowerCase());
        if (checkArray.length === 0) {
          if (selectMapValues?.pharmacyName?.toLowerCase() !== userLoginResPharmacy?.pharmacyName?.toLowerCase()) {
            setSelectMapValues(array.sort((a, b) => a?.bestPrice - b?.bestPrice)[0]);
          }
        }
      }
    }
    else {
      if (selectMapValues?.length === 0 || !allApisCalled) {
        if (userLoginResPharmacy?.pharmacyName) {
          setSelectMapValues(userLoginResPharmacy);
        }
      }
    }
  }

  // set the loading progress bar based on API responses
  const checkProgress = () => {
    if (progress > 0 && progress < 100 && !progressLock) {
      if (rxLessDrugsPrices?.length === medicationList?.length && !responseState?.rxLess) {
        dispatch(setProgress(progress + 20));
        dispatch(setResponseState({
          ...responseState,
          rxLess: true
        }));
      }
      if (rxSenceDrugsPrices?.length === medicationList?.length && !responseState?.rxSense) {
        dispatch(setProgress(progress + 15));
        dispatch(setResponseState({
          ...responseState,
          rxSense: true
        }));
      }
      if (hippoDrugsPrices?.length === medicationList?.length && !responseState?.hippo) {
        dispatch(setProgress(progress + 15));
        dispatch(setResponseState({
          ...responseState,
          hippo: true
        }));
      }
      if (careCardDrugsPrices?.length === medicationList?.length && !responseState?.careCard) {
        dispatch(setProgress(progress + 15));
        dispatch(setResponseState({
          ...responseState,
          careCard: true
        }));
      }
      if ((prismaPrices?.length === medicationList?.length && !responseState?.prisma) || !patientHasInsurance) {
        dispatch(setProgress(progress + 10));
        dispatch(setResponseState({
          ...responseState,
          prisma: true
        }));
      }
      if ((copayPrices?.length === medicationList?.length && !responseState?.copay) || !patientHasInsurance) {
        dispatch(setProgress(progress + 10));
        dispatch(setResponseState({
          ...responseState,
          copay: true
        }));
      }
    }
    if (progress >= 100) {
      dispatch(setProgress(100));
      dispatch(setProgressLock(true));
    }
  }

  useEffect(() => {
    console.log(sumOfBestPrciesAtPharmacies);
    let finalArray = [];
    // set Description for pharmacies list
    if (sumOfBestPrciesAtPharmacies?.length > 0) {
      sumOfBestPrciesAtPharmacies?.map((element) => {
        let rxDrugs = element?.drugs?.filter((x, i) => x?.rxSense);
        let copayDrugs = element?.drugs?.filter((x, i) => x?.hasInsurance);
        // debugger;
        let description = "";
        if (copayDrugs?.length === 0 && rxDrugs?.length === 0) {
          //  description = "with discount card";
          finalArray.push(
            {
              ...element,
              description: "with discount card"
            }
          );
        }
        else if (copayDrugs?.length === 0 && (rxDrugs?.length > 0 && rxDrugs?.length < element?.drugs?.length)) {
          //description = "with RxLink Offer";
          finalArray.push(
            {
              ...element,
              description: "with RxLink Offer"
            }
          );
        }
        else if (copayDrugs?.length === 0 && (rxDrugs?.length > 0 && rxDrugs?.length === element?.drugs?.length)) {
          // description = "with Single Care";
          finalArray.push(
            {
              ...element,
              description: "with Single Care"
            }
          );
        }
        else if (rxDrugs?.length === 0 && (copayDrugs?.length > 0 && copayDrugs?.length < element?.drugs?.length)) {
          //description = "with RxLink Offer";
          finalArray.push(
            {
              ...element,
              description: "with RxLink Offer"
            }
          );
        }
        else if (rxDrugs?.length === 0 && (copayDrugs?.length > 0 && copayDrugs?.length === element?.drugs?.length)) {
          // description = "with insurance";
          finalArray.push(
            {
              ...element,
              description: "with insurance"
            }
          );
        }
        else if (copayDrugs?.length > 0 && rxDrugs?.length > 0 && copayDrugs?.length < element?.drugs?.length && rxDrugs?.length < element?.drugs?.length) {
          //  description = "with RxLink Offer";
          finalArray.push(
            {
              ...element,
              description: "with RxLink Offer"
            }
          );
        }
      });
      setBestPackage(finalArray);
      if (isLoader) {
        if (rxLessDrugsPrices.length === medicationList.length || rxSenceDrugsPrices?.length === medicationList?.length || hippoDrugsPrices?.length === medicationList?.length || careCardDrugsPrices?.length === medicationList?.length) {
          setLoading(false);
          if (loadingPharmacy && !checkList) {
            dispatch(setLoadingPharmacy(false));
            if (updatePharmacyToast) {
              successToast(updatePharmacyToast);
              setUpdatePharmacyToast(null);
            }
          }
        }

      }
    }
    else {
      setBestPackage([]);
      if (isLoader) {
        if (rxLessDrugsPrices.length === medicationList.length || rxSenceDrugsPrices?.length === medicationList?.length || hippoDrugsPrices?.length === medicationList?.length || careCardDrugsPrices?.length === medicationList?.length) {
          setLoading(false);
          if (loadingPharmacy && !checkList) {
            dispatch(setLoadingPharmacy(false));
            if (updatePharmacyToast) {
              successToast(updatePharmacyToast);
              setUpdatePharmacyToast(null);
            }
          }
        }

      }
      else {

      }

    }
  }, [sumOfBestPrciesAtPharmacies, isLoader, checkList])

  useEffect(() => {
    localStorage.removeItem("userBody");

    if (medicationList?.length > 0 && patient?.bin && patient?.groupNumber &&
      patient?.memberId && originalFilter?.length > 0 && originalFilter[0]?.npi && (medicationLength !== medicationList?.length || originalFilter[0]?.npi !== npi || fromSearchMap == true)) {

      setNpi(originalFilter[0]?.npi);
      setMedicationLength(medicationList?.length);
      dispatch(setFromSearchMap(false))
      fetchCopayInfo();

    }
  }, [originalFilter, patient, medicationList]);

  useEffect(() => {
    localStorage.removeItem("userBody");

    if (medicationList?.length > 0 && patientHasInsurance && originalFilter?.length > 0 && originalFilter[0]?.npi) {

      // setNpi(originalFilter[0]?.npi);
      // setMedicationLength(medicationList?.length);
      // dispatch(setFromSearchMap(false))
      fetchCopayInfo();
      fetchMedications(patient);
    }


  }, [patient]);

  async function fetchCopayInfo(list = []) {
    let tempArr = [];
    let medicines = list.length > 0 ? list : medicationList;
    medicines.forEach((x) => {
      let tempSelectedDrug = {
        name: x?.name,
        id: +x?.id,
        type: x?.type,
        search_name: x?.search_name,
        dosage: x?.dosage,
        drugSeqNo: x?.drugSeqNo,
        form: x?.form,
        form_plural: x?.form_plural,
        package_size: x?.package_size,
        quantities: x?.quantities,
        default_quantity: x?.default_quantity !== undefined ? x?.default_quantity : JSON.parse(x?.quantities || [])[0],
        names: x?.names,
        ndc: x?.ndc,
      };
      tempArr.push({

        ndc: tempSelectedDrug?.ndc,
        quantity: tempSelectedDrug?.default_quantity,
        state: ownPosition?.responseZIP?.data?.region_code,
        dosage: tempSelectedDrug?.dosage,
        form: tempSelectedDrug?.form,
        drugSeqNo: tempSelectedDrug?.drugSeqNo,
        drugFullName: tempSelectedDrug?.search_name,
        "firstName": patient?.firstName,
        "lastName": patient?.lastName,
        "date_of_birth": patient?.dob,
        "gender": patient?.gender,
        "bin": patient?.bin,
        "pcn": patient?.pcn,
        "groupNumber": patient?.groupNumber,
        "memberId": patient?.memberId,
        "npi": originalFilter?.[0]?.npi
      });
    });
    let userLoginResPharmacy = pharmacyList?.filter(i => i.isDefault)?.[0];
    let body = {
      appUserSeqNo: JSON.parse(localStorage.getItem("user"))?.appUserSeqNo,
      copayDrugInfo: tempArr,
      state: userLoginResPharmacy?.userPharmacySeqNo ? userLoginResPharmacy?.state : ownPosition?.responseZIP?.region_code,
    }
    dispatch(fetchCopayPrices(body))
      .unwrap()
      .then((res) => {
        if (res?.data?.length > 0) {
          setCopayPrices(res?.data);
        }
      });
    dispatch(fetchPrismaRules(body))
      .unwrap()
      .then((res) => {
        if (res?.data?.length > 0) {
          setPrismaPrices(res?.data);
          // setCopayPrices(res?.data);
          let checkDisclaimer = [];
          res?.data.map((item) => {
            if (item?.portalText?.length > 0) {
              checkDisclaimer.push({ drugSeqNo: item?.drugSeqNo, text: item?.portalText?.[0] })
            }
          })
          if (checkDisclaimer?.length > 0) {
            dispatch(setDisclaimer(checkDisclaimer))
          }
          else {
            dispatch(setDisclaimer([]))
          }
        }
      });
  }

  async function fetchMedications(user) {
    const userHasInsurance = user?.bin && user?.groupNumber && user?.memberId;
    const patientSeqNo = user?.patientSeqNo;
    dispatch(resetProgressOperation("reset"))
    let body = null;
    if (userHasInsurance) {
      let userLoginResPharmacy = user?.pharmacy;
      body = {
        appUserSeqNo: JSON.parse(localStorage.getItem("user"))?.appUserSeqNo,
        dob: user?.dob || "",
        pharmacyState: userLoginResPharmacy?.userPharmacySeqNo ? userLoginResPharmacy?.state : ownPosition?.responseZIP?.region_code,
        patientSeqNo: patientSeqNo
      };
    }
    else {
      body = {
        appUserSeqNo: JSON.parse(localStorage.getItem("user"))?.appUserSeqNo,
        patientSeqNo: patientSeqNo
      };
    }

    localStorage.removeItem("userBody");
    dispatch(fetchMedicationList(body))
      .unwrap()
      .then((response) => {
        setIsLoader(true);
        if (checkList) {
          setCheckList(false);
        }
        if (response?.data?.length > 0) {
          setMedicationsLength(response?.data?.length);
          setMedicationList(response?.data);
          dispatch(setProgress(15));
          let rxLessDrugsBody = [];
          response?.data?.forEach(async (x) => {
            let tempSelectedDrug = {
              name: x?.name,
              id: x?.id,
              type: x?.type,
              search_name: x?.search_name,
              dosage: x?.dosage,
              drugSeqNo: x?.drugSeqNo,
              form: x?.form,
              form_plural: x?.form_plural,
              package_size: x?.package_size,
              quantities: x?.quantities,
              default_quantity: x?.default_quantity !== undefined ? x?.default_quantity : JSON.parse(x?.quantities || [])[0],
              names: x?.names,
              ndc: x?.ndc,
              manufacturerOffer: x?.manufactorInfo,
              connectiveRxOffer: x?.crxNetPriceDTO?.crxNetPriceSubSeqNo ? x?.crxNetPriceDTO : undefined

            };
            rxLessDrugsBody.push(tempSelectedDrug);
            await setHippoPrices(tempSelectedDrug, x);
            await setCostPlusDrugPrices(tempSelectedDrug, x);
            await setRxSencePrices(tempSelectedDrug, x);
            await setCareCardInfo(tempSelectedDrug, x);
          });
          setRxLessPrices(rxLessDrugsBody);
        } else if (response?.status !== "OK") {
          // addToast(response?.message, {
          //   appearance: "error",
          //   autoDismiss: 5000,
          // });
          if (checkList) {
            setCheckList(false);
          }
        }
      })
      .catch((e) => {
        // console.log(e);
        if (checkList) {
          setCheckList(false);
        }
      })
      .finally((e) => {
      });
  }

  async function setHippoPrices(selectedDrug, x) {

    let pharma = pharmacyList.filter(i => i.isDefault)[0];
    let zipCode = null;
    if (pharma != undefined) {
      zipCode = pharma.zipcode
    }
    let quantity = selectedDrug.default_quantity != "" ? selectedDrug.default_quantity : JSON.parse(selectedDrug.quantities)[0];
    let url = `prices?ndc=${selectedDrug?.ndc}&quantity=${quantity}&package_size=${selectedDrug?.package_size
      }&zip=${zipCode ? zipCode : ownPosition?.postalCode}`;
    const tempArray = hippoDrugsPrices?.filter(
      (x, i) => x?.search_name?.toLowerCase() !== selectedDrug?.search_name?.toLowerCase()
    );
    await dispatch(fetchDrugsPrices(url))
      .unwrap()
      .then(async (response) => {
        if (response?.length > 0) {
          const arr = [
            {
              quantity: selectedDrug?.default_quantity,
              package_size: selectedDrug?.package_size,
              search_name: selectedDrug?.search_name,
              drugSeqNo: selectedDrug?.drugSeqNo,
              drugPrices: response,
              selectedDrug: selectedDrug,
            },
          ];
          setHippoDrugsPrices((hippoDrugsPrices) =>
            hippoDrugsPrices
              ?.filter(
                (x, i) =>
                  x?.drugSeqNo !== selectedDrug?.drugSeqNo
              )
              .concat(arr)
          );

        }
        else {
          const arr = [
            {
              quantity: selectedDrug?.default_quantity,
              package_size: selectedDrug?.package_size,
              search_name: selectedDrug?.search_name,
              drugSeqNo: selectedDrug?.drugSeqNo,
              drugPrices: null,
              selectedDrug: selectedDrug,
            },
          ];

          setHippoDrugsPrices((hippoDrugsPrices) =>
            hippoDrugsPrices
              ?.filter(
                (x, i) =>
                  x?.drugSeqNo !== selectedDrug?.drugSeqNo
              )
              .concat(arr)
          );
          if (hippoApiErr === 0) {
            hippoApiErr = 1;
          }

        }
      })
      .catch((e) => {
        setIsLoader(false);
      });
  }

  async function setCostPlusDrugPrices(selectedDrug, x) {
    let quantity = selectedDrug.default_quantity != "" ? selectedDrug.default_quantity : JSON.parse(selectedDrug.quantities)[0];
    let name = selectedDrug.name.toLowerCase() == "atorvastatin calcium" ? "Atorvastatin" : selectedDrug.name;

    let url =
      "https://us-central1-costplusdrugs-publicapi.cloudfunctions.net/main?medication_name=" +
      name +
      "&quantity_units=" +
      quantity;
    await dispatch(fetchCostPlusDrugPrice(url))
      .unwrap()
      .then((res) => {
        if (res?.results?.length > 0) {
          const arr = [{
            selectedDrug: selectedDrug,
            res: res,
            costPlusDrugPrice: res?.results?.length > 0
              ? Number(res?.results[0]?.requested_quote.replace(/[^0-9\.]+/g, ""))
              : 0,
          }]

          setCostPlusDrugsPrices((costPlusDrugsPrices) =>
            costPlusDrugsPrices
              ?.filter(
                (x, i) =>
                  x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
              )
              .concat(arr)
          );
        }
        else {
          const arr = [{
            selectedDrug: selectedDrug,
            res: null,
            costPlusDrugPrice: 0,
          }]

          setCostPlusDrugsPrices((costPlusDrugsPrices) =>
            costPlusDrugsPrices
              ?.filter(
                (x, i) =>
                  x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
              )
              .concat(arr)
          );
        }
      })
      .catch((e) => {
        setIsLoader(false);
      });
  }

  async function setRxSencePrices(selectedDrug, x) {
    let quantity = selectedDrug.default_quantity != "" ? selectedDrug.default_quantity : JSON.parse(selectedDrug.quantities)[0];
    let pharma = pharmacyList.filter(i => i.isDefault)[0];
    let zipCode = null;
    if (pharma != undefined) {
      zipCode = pharma.zipcode
    }
    await dispatch(fetchRxSenseDrugPrices({
      ndc: selectedDrug.ndc,
      quantity: parseFloat(quantity).toFixed(1),
      zipCode: zipCode ? zipCode : ownPosition?.postalCode
    })).unwrap().then((response) => {

      if (response) {
        console.log({ rxSenseResponse: JSON.parse(response?.data) })
        const arr = [
          {
            quantity: selectedDrug?.default_quantity,
            package_size: selectedDrug?.package_size,
            search_name: selectedDrug?.search_name,
            drugSeqNo: selectedDrug?.drugSeqNo,
            selectedDrug: selectedDrug,
            rxsenseResponse: JSON.parse(response?.data)
          },
        ];
        setRxSenceDrugsPrices((rxSenceDrugsPrices) =>
          rxSenceDrugsPrices
            ?.filter(
              (x, i) =>
                x?.drugSeqNo !== selectedDrug?.drugSeqNo
            )
            .concat(arr)
        )
        let phamacyList = JSON.parse(response?.data).Success ?
          JSON.parse(response?.data).Value.PharmacyPricings.map((pharmacy) => {
            return {
              pharmacyName: pharmacy.Pharmacy.Name.toLowerCase(),
              chain: pharmacy.Pharmacy.Name.toLowerCase().split(" ")[0],
              npi: pharmacy.Pharmacy.NPI
            }
          })
          : [];
        console.log({ "**": phamacyList })
        dispatch(setRxSenseDrugDetails(phamacyList))

        if (sessionStorage.getItem("selectMapValues")) {

          const selectMapValues = JSON.parse(
            sessionStorage.getItem("selectMapValues")
          );
          setSelectMapValues(selectMapValues);
        }
        else {
          if (selectMapValues.length == 0 && phamacyList.length > 0)
            setSelectMapValues(Object.assign({}, phamacyList[0]));
        }
      }

    }).catch((err) => {
      const arr = [
        {
          quantity: selectedDrug?.default_quantity,
          package_size: selectedDrug?.package_size,
          search_name: selectedDrug?.search_name,
          drugSeqNo: selectedDrug?.drugSeqNo,
          selectedDrug: selectedDrug,
          rxsenseResponse: null
        },
      ];

      setRxSenceDrugsPrices((rxSenceDrugsPrices) =>
        rxSenceDrugsPrices
          ?.filter(
            (x, i) =>
              x?.drugSeqNo !== selectedDrug?.drugSeqNo
          )
          .concat(arr)
      );
    })
  }

  async function setCareCardInfo(selectedDrug, x) {

    let quantity = selectedDrug.default_quantity != "" ? selectedDrug.default_quantity : JSON.parse(selectedDrug.quantities)[0];
    console.log(ownPosition);
    await dispatch(fetchCareCardInfo({
      ndc: selectedDrug?.ndc,
      quantity: +quantity,
      appUserSeqNo: user?.appUserSeqNo,
      zipCode: userPharmacy?.zipcode ? userPharmacy?.zipcode : ownPosition?.postalCode
    })).unwrap().then((response) => {
      if (response?.data?.length > 0) {

        const arr = [
          {
            quantity: +quantity,
            package_size: selectedDrug?.package_size,
            search_name: selectedDrug?.search_name,
            drugSeqNo: selectedDrug?.drugSeqNo,
            selectedDrug: selectedDrug,
            careCardResponse: response?.data?.map((x, i) => { return { ...x, pharmacy: x?.pharmacy, chain: x?.pharmacy?.name.toLowerCase() } })
          },
        ];
        setCareCardDrugsPrices((rxSenceDrugsPrices) =>
          rxSenceDrugsPrices
            ?.filter(
              (x, i) =>
                x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
            )
            .concat(arr)
        );

      }
      else {
        const arr = [
          {
            quantity: +quantity,
            package_size: selectedDrug?.package_size,
            search_name: selectedDrug?.search_name,
            drugSeqNo: selectedDrug?.drugSeqNo,
            selectedDrug: selectedDrug,
            careCardResponse: null
          },
        ];

        setCareCardDrugsPrices((rxSenceDrugsPrices) =>
          rxSenceDrugsPrices
            ?.filter(
              (x, i) =>
                x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
            )
            .concat(arr)
        );
      }
    }).catch((err) => {
      const arr = [
        {
          quantity: +quantity,
          package_size: selectedDrug?.package_size,
          search_name: selectedDrug?.search_name,
          drugSeqNo: selectedDrug?.drugSeqNo,
          selectedDrug: selectedDrug,
          careCardResponse: null
        },
      ];

      setCareCardDrugsPrices((rxSenceDrugsPrices) =>
        rxSenceDrugsPrices
          ?.filter(
            (x, i) =>
              x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
          )
          .concat(arr)
      );
    })
  }

  const getRxLessDrugResponseMapper = (pharmData, drug) => {
    let drugPhamracies = [];
    const existingPharmacyIndex = (pharma) => {
      let index = drugPhamracies.findIndex(item => item.pharmacyName == pharma.pharmacyName);
      if (index != -1 && pharma.price < drugPhamracies[index]?.price) {
        drugPhamracies[index] = pharma
      }
      else if (index == -1) {
        drugPhamracies.push(pharma)
      }
    }
    pharmData.map((pharm) => {
      let containsDrug = pharm.meds.filter((x1) => x1.ndc == drug.ndc);
      if (containsDrug.length > 0) {
        let model = {
          pharmacyName: pharm.name,
          chain: pharm.name.toLowerCase(),
          city: pharm.city,
          zipCode: pharm.zip,
          lat: pharm.lat,
          lng: pharm.lng,
          state: pharm.state,
          npi: pharm.npi,
          quantity: containsDrug[0].qty,
          ndc: containsDrug[0].ndc,
          price: Math.min(...containsDrug[0].prices.map(price => price.amount)),
          bin: containsDrug[0].prices[0].bin,
          pcn: containsDrug[0].prices[0].pcn,
          groupId: containsDrug[0].prices[0].groupId,
          memberId: containsDrug[0].prices[0].memberId
        }
        existingPharmacyIndex(model)
      }
    })
    return drugPhamracies
  }


  async function setRxLessPrices(selectedDrugs) {
    let postalCode = userPharmacy?.zipcode ? userPharmacy?.zipcode : ownPosition.postalCode;
    console.log(userPharmacy);
    await dispatch(fetchRxLessDrugPrices({
      appUserSeqNo: user?.appUserSeqNo,
      zipCode: postalCode,
      medications: selectedDrugs.map((drug, i) => {
        return {
          ndc: drug.ndc,
          qty: +(drug.default_quantity != "" ? drug.default_quantity : JSON.parse(drug.quantities)[0])
        }
      })
    })).unwrap().then((response) => {
      if (response?.data?.pharmData?.length > 0) {
        selectedDrugs?.map((drug, index) => {
          console.log({ rxless: getRxLessDrugResponseMapper(response?.data?.pharmData, drug) })

          let payload = [
            {
              quantity: +(drug.default_quantity != "" ? drug.default_quantity : JSON.parse(drug.quantities)[0]),
              package_size: drug?.package_size,
              search_name: drug?.search_name,
              drugSeqNo: drug?.drugSeqNo,
              selectedDrug: drug,
              rxLessResponse: getRxLessDrugResponseMapper(response?.data?.pharmData, drug)
            }
          ]
          setRxLessDrugsPrices((rxSenceDrugsPrices) =>
            rxSenceDrugsPrices
              ?.filter(
                (x, i) =>
                  x?.selectedDrug?.drugSeqNo !== drug?.drugSeqNo
              )
              .concat(payload)
          );
        })
      }
      else {
        selectedDrugs?.map((drug) => {
          let payload = [
            {
              quantity: +(drug.default_quantity != "" ? drug.default_quantity : JSON.parse(drug.quantities)[0]),
              package_size: drug?.package_size,
              search_name: drug?.search_name,
              drugSeqNo: drug?.drugSeqNo,
              selectedDrug: drug,
              rxLessResponse: null
            }
          ]
          setRxLessDrugsPrices((rxSenceDrugsPrices) =>
            rxSenceDrugsPrices
              ?.filter(
                (x, i) =>
                  x?.selectedDrug?.drugSeqNo !== drug?.drugSeqNo
              )
              .concat(payload)
          );
        })
      }
    }).catch((err) => {
      selectedDrugs?.map((drug) => {
        let payload = [
          {
            quantity: +(drug.default_quantity != "" ? drug.default_quantity : JSON.parse(drug.quantities)[0]),
            package_size: drug?.package_size,
            search_name: drug?.search_name,
            drugSeqNo: drug?.drugSeqNo,
            selectedDrug: drug,
            rxLessResponse: null
          }
        ]
        setRxLessDrugsPrices((rxSenceDrugsPrices) =>
          rxSenceDrugsPrices
            ?.filter(
              (x, i) =>
                x?.selectedDrug?.drugSeqNo !== drug?.drugSeqNo
            )
            .concat(payload)
        );
      })
    })
  }

  function resetInsuranceDetail() {
    setInsuranceDetail([]);
    setHasInsurance(null);
  }

  const AddOrUpdatePharmacy = async (pharmacy) => {
    await addOrUpdatePharmacy(pharmacy, (async (data) => {
      if (data.data.statusCode == 200) {
        dispatch(resetProgressOperation("reset"));



        await getUserPharmacyList((data) => {
          setHippoDrugsPrices([]);
          setRxLessDrugsPrices([]);
          setCareCardDrugsPrices([]);
          setRxSenceDrugsPrices([]);
          setCostPlusDrugsPrices([]);
          setMedicationInfo([]);
          setSumOfBestPrciesAtPharmacies([]);
          setBestPackage([]);
          dispatch(setPharmacyList(data.data.data.sort((a, b) => b.isDefault - a.isDefault)));
          dispatch(setShowPahrmacyMap(false));
          dispatch(setFromDrawer(false));

          dispatch(setPatient({
            ...patient,
            pharmacy: data.data.data.sort((a, b) => b.isDefault - a.isDefault)[0]
          }));

          let activePatient = JSON.parse(localStorage.getItem("patient"));
          localStorage.setItem("patient", JSON.stringify({ ...activePatient, pharmacy: data.data.data.sort((a, b) => b.isDefault - a.isDefault)[0] }))


          setPharmacyFlag(false);
          setSelectMapValues(pharmacy)
          setUpdatePharmacyToast("Default Pharmacy has been updated.");
          setCheckList(true)
        })
      }
      else {
        errorToast(data.data.message);
        setLoading(false)
      }
    }))
  }

  const RemovePharmacy = async (pharmacyNo) => {
    if (disabledIcons[pharmacyNo]) return;
    setDisabledIcons(prevState => ({ ...prevState, [pharmacyNo]: true }));
    await removePharmacy(pharmacyNo, (async (data) => {
      successToastWithId("Pharmacy has been removed.", "remove-pharmacy")
      await getUserPharmacyList((data) => {
        dispatch(setPharmacyList(data.data.data.sort((a, b) => b.isDefault - a.isDefault)));
        setDisabledIcons(prevState => ({ ...prevState, [pharmacyNo]: false }));
        let checkDefaultPharmacy = data?.data?.data?.filter(a => a.isDefault);
        if (checkDefaultPharmacy?.length < 1) {
          setCheckList(true);
          setPharmacyFlag(false);
          dispatch(resetProgressOperation("reset"));
        }
      })
    }))
  }

  function onChangeSearch(event) {
    setDeleteShow(false);
    setSearchDrug(event.target.value);
    if (event.target.value) dispatch(searchDrugsByName(event.target.value));
    else setOptionss([]);
  }

  const checkInsurance = (id, callBack) => {
    let userHasInsurance = JSON.parse(localStorage.getItem("user"))?.hasInsurance;

    if (!patientInsuranceInfo) {
      let user = JSON.parse(localStorage.getItem("user"));

      let body = {
        appUserSeqNo: user?.appUserSeqNo,
      };
      dispatch(fetchInsuranceInfo(body)).unwrap().then((response) => {
        if (response?.data?.length > 0) {
          let insuranceObj = {
            firstName: response.data[0]?.firstName,
            lastName: response.data[0]?.lastName,
            date_of_birth: response.data[0]?.dob,
            gender: response.data[0]?.gender,
            bin: response.data[0]?.bin,
            pcn: response.data[0]?.pcn,
            groupNumber: response.data[0]?.groupNumber,
            memberId: response.data[0]?.memberId,
            insInfoSeqNo: response.data[0]?.insInfoSeqNo
          };
          dispatch(setInsuranceInfo(response.data[0]))
          setInsuranceDetail((insurance) => insurance.concat(insuranceObj));
          callBack(id, insuranceObj);
        }
        else {
          setHasInsurance(!hasInsurance);
          callBack(id, null);

        }

      }).catch((e) => {
        console.log(e);
      }).finally((e) => {

      });
    }
    else {
      callBack(id, null);
    }
  }

  const getInsuranceNumber = (insurance, insuranceObj) => {
    let objToReturn = {};
    if (!insuranceObj && insurance?.bin && insurance?.groupNumber && insurance?.memberId) {
      objToReturn = {
        bin: insurance?.bin,
        groupNumber: insurance?.groupNumber,
        memberId: insurance?.memberId,
        pcn: insurance?.pcn
      }
    }
    else if (insuranceObj && !insurance) {
      objToReturn = {
        bin: insuranceObj?.bin,
        groupNumber: insuranceObj?.groupNumber,
        memberId: insuranceObj?.memberId,
        pcn: insuranceObj?.pcn
      }
    }
    else {
      objToReturn = null
    }

    return objToReturn;
  }


  function drugDetails(drugId, insuranceObj) {
    setMedication({
      drugId: drugId,
    });
    let user = JSON.parse(localStorage.getItem("user"));
    let checkExistingList = customMedications?.filter(med => med.name.toLowerCase() === drugId);
    if (checkExistingList?.length > 0) {
      let request = {
        appUserSeqNo: JSON.parse(localStorage.getItem("user"))
          ?.appUserSeqNo,
        drugSeqNo: null,
        ...checkExistingList[0],
        insurance: getInsuranceNumber(patient, insuranceObj),
        dob: patient?.dob,
        pharmacyState: userHasPharmacy ? userPharmacy.state : ownPosition?.responseZIP?.region_code
      };
      setAddOrUpdateMed(request);
    }
    else if (drugId) {
      setAddingMedicine(true);
      dispatch(fetchMedicationDetail({ name: drugId, appUserSeqNo: user.appUserSeqNo }))
        .unwrap()
        .then((response) => {
          if (response) {
            let mappedData = mapData(JSON.parse(response.data));
            let request = {
              appUserSeqNo: JSON.parse(localStorage.getItem("user"))
                ?.appUserSeqNo,
              drugSeqNo: null,
              ...mappedData,
              insurance: getInsuranceNumber(patient, insuranceObj),
              dob: patient?.dob,
              pharmacyState: userHasPharmacy ? userPharmacy.state : ownPosition?.responseZIP?.region_code
            };
            setAddOrUpdateMed(request);
          }
        })
        .catch((e) => {
          setAddingMedicine(false);

        })
        .finally((e) => {
          resetDrugDetails();

        });

    }
    resetDrugDetails();
  }

  function resetDrugDetails() {
    setMedication({
      drugId: "",
    });
    setOptionss([]);
    setSearchDrug("");
  }

  function logout() {
    localStorage.clear();
    sessionStorage.clear();
    // setAuth(null);
    navigate("/");
  }

  const showDelete = () => {
    if (deleteShow) {
      setDeleteShow(false);
    } else {
      setDeleteShow(true);
    }
  };

  const setFinalPrices = (prices) => {
    setPricesList(prices);
  }

  function getPatientSavingsHistory(appUserSeqNo) {
    if (patient)
      fetchMedications(patient);
    else {
      setIsLoader(false);
      navigate("/patient");
    }
  }


  const pharmaAddress = originalFilter?.[0];
  return (
    <>
      {(loading || loadingPharmacy) ? (
        <div className="loader"></div>
      ) : (
        <>
          {!showPharmacyMap && !showCoupon && <section className="mt-3 mb-5" ref={ref}>
            <div className="container">
              {addingMedicine &&
                <div style={{ position: "absolute", top: "50%", left: "50%", zIndex: 1001 }}><Spinner animation="border" variant="primary" /></div>
              }
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="medicine_icon medicine_icon_m">

                    <div className="">
                      <motion.img
                        whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
                        src={avatar}
                        className="img-fluid pointer"
                        id="dropdownMenuButton1"
                        aria-expanded="false"
                        type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                      ></motion.img>

                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item item-1"
                          onClick={() => logout()}
                        >
                          Sign out
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {patient?.createdDateTime && (
                <>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="medication-heading" style={{ marginBottom: 0, display: windowWidth > 991 ? "inline-flex" : "block" }}>
                        <div className="extra-close"><MotionTextH2 text="Patient savings on" duration={0.03} delay={0.15} /></div><div style={{ marginLeft: windowWidth > 991 ? 4 : 0, marginTop: windowWidth > 991 ? 0 : -10 }}>
                          <MotionTextH2 text={patient?.createdDateTime} duration={0.03} delay={0.15} /></div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row" style={{ marginTop: -16 }}>
                {patient?.firstName && (
                  <>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 medications_detail" style={{ display: 'flex' }}>
                        <h4>{patient?.firstName} {patient?.lastName}</h4>
                        <div className="edit-Icon" style={{ paddingLeft: 12, marginTop: -3 }}>
                          <img className="cursor-pointer" src={editPencil} onClick={() => setShowEditPatient(true)} />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="row input-div">
                <div className="col-sm-12 col-md-12 col-lg-9">
                  <div className="row">
                    <div className={medicationList?.length > 0 ? 'col-11 col-sm-11 col-md-12 col-lg-12' : 'col-12 col-sm-12 col-md-12 col-lg-12'}
                    >
                      <i className="search_icon">
                        <img src={search} />
                      </i>
                      <input
                        value={searchDrug}
                        onChange={onChangeSearch}
                        placeholder="Search for a medication"
                        className="search-bar autoMargin medication-search-margin"
                      />

                    </div>
                    {optionss?.length > 0 && searchDrug != "" && (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="searchBar-icon ">
                          {optionss.map((searchResult) => (
                            <li
                              tabIndex={0}
                              className="list-style"
                              onClick={() =>
                                drugDetails(searchResult.id)
                              }
                            >
                              <a>{searchResult.name}</a>
                              <h5>{searchResult.secondary_name}</h5>
                            </li>
                          ))}
                        </div>
                      </div>
                    )}

                    {medicationList?.length > 0 && (<div
                      className="col-1 col-sm-1 col-md-12 col-lg-12 mobileView ps-0"
                      onClick={() => showDelete()}
                      style={{ textAlign: "end" }}
                    >
                      <MotionIcon>
                        <div className="vertical-ellipse">
                          <img className=""
                            src={trash}
                          ></img>
                        </div>
                      </MotionIcon>

                    </div>)}
                    {(medicationList.length == 0 && !searchDrug && !addingMedicine) && (
                      <div>
                        {windowWidth > 600 ?
                          <>
                            <img src={arrowDesktop} style={{ height: 43, position: "relative", top: -45, left: -20 }} />
                            <img src={desktopImage} style={{ position: "relative", top: -53, left: -10, maxWidth: "102%" }} className="img-fluid" />
                          </>

                          : <>
                            <img src={arrowMobile} style={{ height: 36, position: "relative", top: -45, left: -12 }} />
                            <img src={mobileImage} style={{ position: "relative", top: -54, left: -13, maxWidth: "103%" }} className="img-fluid" />
                          </>
                        }
                      </div>
                    )}
                  </div>
                  <div className="tabMargin">
                    <PricesDetails
                      medicationInfo={medicationInfo}
                      setMedicationInfo={setMedicationInfo}
                      setAddOrUpdateMed={setAddOrUpdateMed}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      label={label}
                      setLabel={setLabel}
                      form={form}
                      setForm={setForm}
                      dosage={dosage}
                      setDosage={setDosage}
                      setRemoveDrug={setRemoveDrug}
                      bestPackage={bestPackage}
                      deleteShowParam={deleteShow}
                      patient={patient}
                      ownPosition={ownPosition}
                      userPharmacy={userPharmacy}
                      userHasPharmacy={userHasPharmacy}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3 couponMobFixed">
                  <RightReviewCoupon
                    medParam={medicationCoupon}
                    valParam={originalFilter != null && originalFilter[0]}
                    setSavingOptionsPram={setSavingOptions}
                    savingOptionParam={SavingOptions}
                    refParam={ref}
                    medDrugData={medicationInfo}
                    ownPosition={ownPosition}
                    pricesList={pricesList}
                    cheaperOptionAvailable={cheaperOptionAvailable}
                    windowWidth={windowWidth}
                    medicationInfo={medicationInfo}
                    bestPackage={bestPackage}
                    ref={rightReviewRef}
                    insuranceDetail={insuranceDetail}
                    pharmaAddress={pharmaAddress}
                    patient={patient}
                    prismaPrices={prismaPrices}
                  />
                </div>
              </div>
            </div>
          </section>}
          {
            showPharmacyMap &&
            <SearchPharmacy
              ownPosition={ownPosition}
              setOwnPosition={setOwnPosition}
              pharmacyList={pharmacyList}
              setPharmacyFlag={setPharmacyFlag}
              setPharmacyList={(list) => dispatch(setPharmacyList(list))}
              AddOrUpdatePharmacy={AddOrUpdatePharmacy}
              RemovePharmacy={RemovePharmacy}
              drugPrices={drugPrices}
              resetInsurance={resetInsuranceDetail}
              setLoading={setLoading}
            />
          }
        </>
      )}
      {showCoupon &&
        <CouponCard ownPosition={ownPosition} cheaperOptionAvailable={cheaperOptionAvailable} windowWidth={windowWidth} />
      }
      <EditPatient
        isOpen={showEditPatient}
        handleClose={() => setShowEditPatient(false)}
        patient={patient}
        setPatient={setPatientInfo}
      />
    </>
  );
};



export default MedicationSearch;